<template>
  <v-app-bar
    id="app-bar"
    absolute
    app
    color="transparent"
    flat
    height="75"
  >

    <v-btn
      class="mr-3"
      elevation="1"
      fab
      small
      @click="setDrawer(!drawer)"
    >
      <v-icon v-if="value">
        mdi-view-quilt
      </v-icon>

      <v-icon v-else>
        mdi-dots-vertical
      </v-icon>
    </v-btn>

    <v-toolbar-title
      class="hidden-sm-and-down font-weight-light"
      v-text="$route.name"
    />

    <v-spacer />
    <p class="text-right mr-5 mt-5">
      Howdy, {{userName}}
    </p>
    <v-btn small color="primary mr-5" @click="gotoclientportal()">
              Content Portal
        </v-btn>
        <v-icon size="26" title="Sign Out" color="primary"  @click="logout">
        mdi-power
      </v-icon>
    <div class="mx-3" />

  </v-app-bar>
</template>
<script>
  // Components
  import { VHover, VListItem } from 'vuetify/lib'

  // Utilities
  import { mapState, mapMutations, mapGetters } from 'vuex'
  import axios from 'axios';

  export default {
    name: 'DashboardCoreAppBar',

    components: {
      AppBarItem: {
        render (h) {
          return h(VHover, {
            scopedSlots: {
              default: ({ hover }) => {
                return h(VListItem, {
                  attrs: this.$attrs,
                  class: {
                    'black--text': !hover,
                    'white--text secondary elevation-12': hover,
                  },
                  props: {
                    activeClass: '',
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                }, this.$slots.default)
              },
            },
          })
        },
      },
    },

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      notifications: [
        'Mike John Responded to your email',
        'You have 5 new tasks',
        'You\'re now friends with Andrew',
        'Another Notification',
        'Another one',
      ],
    }),

    computed: {
      // ...mapState(['drawer']),
      ...mapGetters({
      drawer: 'Rawdata/getDrawer',
    }),
    userName: function(){
      return this.$utils.getCookies('username')
    }
    },

    methods: {
      setDrawer: function(val){
        this.$store.dispatch("Rawdata/setDrawer", val)
      },
      gotoclientportal(){
        var view = this;
        window.open(
          view.$url("CLIENT_FRONTEND"),
          "_blank"
        );
        window.focus();
        },
        logout() {
        var view = this
        try {
              let signout_url = view.$url('SIGNOUT_URL')
              axios.get(signout_url, {
                  headers: {
                    Authorization: this.$utils.getCookies('access_token'),
                    Userid : this.$utils.getCookies('user_id')
                  }
              }).then(res => {
                  if(res.data.statusCode === 200){
                        view.$utils.setCookies('isSignedIn', false,view)
                        view.$utils.removeCookies('access_token',view)
                        view.$utils.removeCookies('lastVisitedUrl',view)
                        view.$utils.removeCookies('email',view)
                        view.$utils.removeCookies('username',view)
                        view.$utils.removeCookies('user_id',view)
                        view.$utils.removeCookies('un_pass',view)
                        view.$utils.removeCookies('refresh_token',view)
                        view.$utils.removeCookies('portal_access',view)
                        view.$utils.removeCookies('token_expire_time',view)
                        view.$utils.removeCookies('startTime',view)
                        view.$utils.removeCookies('precedence_url',view)
                        view.$utils.removeCookies('user_role',view)
                        location.href = view.$url('SIGNIN_URL')
                    }
                }).catch((error) => {
                  console.error(error);
                });
            }catch(error){
              console.log('error signing out: ', error)
            }
    },
      },

  }
</script>
